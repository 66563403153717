import React, {useEffect} from 'react';
import 'less/Registration/registrationStartPage.less';
import {RegistrationHeader} from './RegistrationHeader';
import {Screen} from './helpers';

export const PlaseVerifyAccount = () => {
    useEffect(() => {
        dataLayer.push({'event': 'registrationStep','stepName': 'PleaseVerifyAccount'});
    }, []);

    return (
        <div
            style={{
                maxWidth: 450,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                marginTop: 50,
                flexDirection: 'column',
            }}
        >
            <h4 className="existing-account">
        A temporary password has been sent to you by email. Please click the
        link in the email to verify your account. You will be prompted to change
        your password once you sign in.
            </h4>
        </div>
    );
};
