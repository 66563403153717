import React from 'react';
import {RegistrationHeader} from './RegistrationHeader';
import {Screen, isValidText, isValidAddressText, isValidEmail} from './helpers';
import 'less/Registration/registrationStartPage.less';
import {RegistrationSecondStep} from './SecondStep';
import {AccountAlreadyExists} from './AccountAlreadyExists';
import {PlaseVerifyAccount} from './PleaseVerifyAccount';
import {HealthSystemRequest} from './HealthSystemRequest';
import axios from 'axios';
import {RegistrationAddressDialog} from './RegistrationAddressDialog';
import RegistrationFirstStep from './FirstStep';
import * as NetworkStore from 'stores/Network';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {logEvent} from 'utility';

@withRouter
@connect((state) => ({network: state.network}), NetworkStore.actionCreators)
export default class RegistrationStartPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBusinessAccount: true,
            isTaxExempt: null,
            isExistingOrganization: false,
            emailAddress: '',
            address1: '',
            address2: '',
            firstName: '',
            lastName: '',
            zip: '',
            ext: '',
            city: '',
            phoneNumber: '',
            country: '',
            state: '',
            organizationTypeId: '',
            positionTypeId: '',
            address: {},
            suggestedAddress: null,
            companyName: '',
            selectedCountry: {value: 'US', text: 'United States'},
            selectedState: {value: '', text: ''},
            selectedOrganizationType: {value: '', text: ''},
            selectedPositionType: {value: '', text: ''},
            selectedAddressOption: '2',
            validating: false,
            department: '',
            showAddressDialog: false,
            screen: Screen.CREATE,
            loading: false,
            registrationType: '',
            hasResponse: false,
            companyTypes: [],
            companyPositionTypes: [],
            dataWithSuggestedAddress: {},
            validatedFields: {},
            token: '',
            widgetId: '',
        };
    }

    onFieldChange = (e) => {
        const fieldName = e.target.id;
        if (fieldName === 'selectedCountry' || fieldName === 'selectedState' || fieldName === 'selectedOrganizationType' || fieldName === 'selectedPositionType') {
            this.setState({[fieldName]: {value: e.target.value, text: this.getTextByValue(e.target.value, fieldName)}});
        } else {
            this.setState({[fieldName]: e.target.value});
        }
    };
    
    componentDidMount() {
        this.loadCompanyTypes();
        this.loadCompanyPositionTypes();
        dataLayer.push({'event': 'registrationStep','stepName': 'RegistrationStartPage'});

        if (window.turnstile) {
            const widgetId = window.turnstile.render('#turnstile', {
                sitekey: process.env.REACT_APP_TURNSTILE_KEY,
                callback: (token) => {
                    this.setState({token});
                },
            });
            this.setState({widgetId});
        }
    }

    getTextByValue = (value, fieldValue) => {
        switch (fieldValue) {
            case 'selectedOrganizationType':
                return this.state.companyTypes.find(x => x.value == value).text;
            case 'selectedPositionType':
                return this.state.companyPositionTypes.find(x => x.value == value).text;
        }
    }

    loadCompanyTypes = () =>
        axios
            .get(`/ShoppingService/api/v1/companies/types`)
            .then((res) => this.setState({companyTypes: res.data}));

    loadCompanyPositionTypes = () =>
        axios
            .get(`/ShoppingService/api/v1/companies/position-types`)
            .then((res) => this.setState({companyPositionTypes: res.data}));

    handleOnSave = () => {
        this.setState({loading: true})
        this.validateAddress();
    };

    handleOnBlur = e => {
        let {validatedFields} = this.state;

        const id = e.target.id;
        const val = (e.target.value).trimStart().trimEnd();

        if (id === 'emailAddress')
            validatedFields[id] = isValidEmail(val);
        else if (id === 'address1' || id === 'address2')
            validatedFields[id] = isValidAddressText(val);
        else validatedFields[id] = isValidText(val);

        this.setState({[id]: val, validatedFields});
    }

    handleOnCancel = () => {
        const {
            firstName,
            lastName,
            emailAddress,
            isBusinessAccount,
            registrationType,
        } = this.state;

        this.logWebEvent(firstName, lastName, emailAddress, isBusinessAccount, registrationType);
    }

     validateBeforeSave = e => {
         let {validatedFields} = this.state;
         const id = e.target.id;
         
         if (id === 'address1' || id === 'address2') 
             validatedFields[id] = isValidAddressText(e.target.value);
         else validatedFields[id] = isValidText(e.target.value);

         this.setState({validatedFields});
         this.onFieldChange(e);
     }

    handleOnContinue = () => {
        const {
            firstName,
            lastName,
            emailAddress,
            isBusinessAccount,
        } = this.state;

        this.setState({loading: true})
        axios.get(`/ShoppingService/api/v1/account/workflow?email=${emailAddress}`).then(res => {   
            if (res.data === Screen.EXISTING_ACCOUNT) {
                this.logWebEvent(firstName, lastName, emailAddress, isBusinessAccount, res.data);
            }
            this.setState({screen: Screen[res.data], registrationType: res.data, isExistingOrganization: res.data === Screen.EXISTING_ORGANIZATION, loading: false})
        });
    };

    validateAddress = () => {
        const {selectedPositionType, address, zip, city, address1, address2, selectedOrganizationType, selectedState, selectedCountry} = this.state;
       
        let data = {...this.state};

        address.street1 = address1;
        address.street2 = address2;
        address.postalCode = zip;
        address.city = city;
        address.state = selectedState.value;
        address.country = selectedCountry.value;
        
        data.state = selectedState.value;
        data.country = selectedCountry.value;
  
        data.positionTypeId = selectedPositionType.value || 68;
        data.organizationTypeId = selectedOrganizationType.value || 21;
        
        data.organizationTypeText = selectedOrganizationType.text;
        data.positionTypeText = selectedPositionType.text;
  
        axios
            .post('/ShoppingService/api/v1/company/address/validate', address)
            .then((result) => {
                let a = result.data;
                if (a.isValid && a.isOriginalAddressValid) {
                    this.register(data);
                } else if (!a.isOriginalAddressValid && a.alternateAddress) {

                    data.dataWithSuggestedAddress = {...data};

                    data.dataWithSuggestedAddress.address1 = a.alternateAddress.street1;
                    data.dataWithSuggestedAddress.address2 = a.alternateAddress.street2;
                    data.dataWithSuggestedAddress.city = a.alternateAddress.city;
                    data.dataWithSuggestedAddress.state = a.alternateAddress.state;
                    data.dataWithSuggestedAddress.country = a.alternateAddress.country;
                    data.dataWithSuggestedAddress.zip = a.alternateAddress.postalCode;

                    data.showAddressDialog = true;
                    data.suggestedAddress = a.alternateAddress;
                    
                    this.setState(data);
                } else {
                    data.showAddressDialog = true;
                    data.suggestedAddress = null;
                    data.selectedAddressOption = '1';
                    data.validating = false;
            
                    this.setState(data);
                }
            }).catch(() => {
                data.showAddressDialog = true;
                data.suggestedAddress = null;
                data.selectedAddressOption = '1';
                data.validating = false;
        
                this.setState(data);
            })
    }

    logWebEvent = (firstName, lastName, emailAddress, isBusinessAccount, registrationType) => {
        let regType = 'New';
        switch (registrationType) {
            case Screen.EXISTING_ORGANIZATION: 
                regType = 'HealthSystem';
                break;
            case Screen.EXISTING_ACCOUNT: 
                regType = 'Existing';
                break;
            case Screen.COMPLETE_PROFILE: 
                regType = 'New';
                break;
        }

        logEvent('REGISTRATION SELECTED', {
            'User Type': isBusinessAccount ? 'Business' : 'Personal',
            'First Name': firstName,
            'Last Name': lastName,
            'Email Address': emailAddress,
            'Registration Type': regType,
        });
    }

    render() {
        const {
            firstName,
            lastName,
            emailAddress,
            isBusinessAccount,
            isTaxExempt,
            selectedCountry,
            countries,
            selectedState,
            suggestedAddress,
            isExistingOrganization,
            phoneNumber,
            address1,
            address2,
            city,
            zip,
            ext,
            department,
            companyName,
            selectedAddressOption,
            selectedOrganizationType,
            selectedPositionType,
            showAddressDialog,
            validating,
            screen,
            companyPositionTypes,
            companyTypes,
            loading,
            hasResponse,
            validatedFields,
        } = this.state;

        let originalAddressLabel = `<div>${address1}</div>`;
        if (address2) originalAddressLabel += `<div>${address2}</div>`;
        originalAddressLabel += `<div>${city}, ${selectedState.value} ${zip}</div>`;
        originalAddressLabel += `<div>${selectedCountry.value}</div>`;

        let suggestedAddressLabel;
        if (suggestedAddress) {
            suggestedAddressLabel = `<div>${suggestedAddress.street1}</div>`;
            if (suggestedAddress.address2)
                suggestedAddressLabel += `<div>${ suggestedAddress.street2}</div>`;
            suggestedAddressLabel += `<div>${suggestedAddress.city}, ${suggestedAddress.state} ${suggestedAddress.postalCode}</div>`;
            suggestedAddressLabel += `<div>${suggestedAddress.country}</div>`;
        }

        return (
            <section className="registration-start-page">
                <div id="turnstile"></div>
                <RegistrationHeader currentScreen={ (isExistingOrganization && !hasResponse) ? Screen.EXISTING_ORGANIZATION : screen} />

                {screen === Screen.CREATE && (
                    <RegistrationFirstStep
                        firstName={firstName}
                        lastName={lastName}
                        emailAddress={emailAddress}
                        isBusinessAccount={isBusinessAccount}
                        onFieldChange={this.onFieldChange}
                        onBlur = {this.handleOnBlur}
                        loading={loading}
                        validatedFields={validatedFields}
                        validateBeforeSave = {this.validateBeforeSave}
                        handleOnContinue={this.handleOnContinue}
                    />
                )}

                {screen === Screen.EXISTING_ACCOUNT && <AccountAlreadyExists />}

                {(screen === Screen.COMPLETE_PROFILE || isExistingOrganization && !hasResponse) && (
                    <RegistrationSecondStep
                        selectedState={selectedState}
                        countries={countries}
                        selectedCountry={selectedCountry}
                        isBusinessAccount={isBusinessAccount}
                        isTaxExempt={isTaxExempt}
                        isExistingOrganization={isExistingOrganization}
                        city={city}
                        loading={loading}
                        address1={address1}
                        selectedOrganizationType={selectedOrganizationType}
                        selectedPositionType={selectedPositionType}
                        address2={address2}
                        phoneNumber={phoneNumber}
                        zip={zip}
                        ext={ext}
                        companyPositionTypes={companyPositionTypes}
                        companyTypes = {companyTypes}
                        department={department}
                        companyName={companyName}
                        validatedFields={validatedFields}
                        validateBeforeSave = {this.validateBeforeSave}
                        onFieldChange={this.onFieldChange}
                        handleOnSubmit={this.handleOnSave}
                        handleOnCancel={this.handleOnCancel}
                        onBlur = {this.handleOnBlur}
                    />
                )}

                {screen === Screen.VERIFY_ACCOUNT && <PlaseVerifyAccount />}

                {screen === Screen.HEALTH_SYSTEM_REQUEST && <HealthSystemRequest /> }

                {showAddressDialog && (
                    <RegistrationAddressDialog
                        originalAddressLabel={originalAddressLabel}
                        suggestedAddressLabel={suggestedAddressLabel}
                        suggestedAddress={suggestedAddress}
                        originalChecked={selectedAddressOption == '1'}
                        validating={validating}
                        handleOnChange={this.onAddressCheck}
                        handleOnSave={this.onAddressSave}
                        handleOnClose={this.onAdressDialogClose}
                    />
                )}
            </section>
        );
    }

    onAdressDialogClose = () => {
        this.setState({showAddressDialog: false});
    };
    onAddressCheck = (e) => {
        this.setState({selectedAddressOption: e.target.value});
    };

    register(request) {
        const {register} = this.props;
        const {token, widgetId} = this.state;
        const {
            firstName,
            lastName,
            emailAddress,
            isBusinessAccount,
            registrationType,
        } = this.state;

        register({ loginInfo: request, token })
            .then(({response: {status, data}}) => {
                if (data.result === 0) { // success
                    this.setState({screen: Screen.VERIFY_ACCOUNT});
                    this.logWebEvent(firstName, lastName, emailAddress, isBusinessAccount, registrationType);
                } else if (data.result === 1) { // fail
                    alert('registration failed!')
                } else if (data.result === 2) { // fail
                    this.setState({screen: Screen.EXISTING_ACCOUNT});
                } else if (data.result === 3) {
                    this.setState({screen: Screen.HEALTH_SYSTEM_REQUEST});
                } else {
                    alert('Something went wrong!')
                }

                this.setState({loading: false, hasResponse: true})
            }).finally(_ => {
                if (window.turnstile) {
                    window.turnstile.reset(widgetId);
                }
            });
    }

    onAddressSave = () => {
        const {validating, dataWithSuggestedAddress, selectedAddressOption} = this.state;
        this.setState({showAddressDialog: false, loading: true});
        if (!validating) {
            this.register(selectedAddressOption == 1 ? this.state : dataWithSuggestedAddress);
        }
    };
}
