export const Screen = {
    CREATE: 'CREATE',
    COMPLETE_PROFILE: 'COMPLETE_PROFILE',
    EXISTING_ACCOUNT: 'EXISTING_ACCOUNT',
    VERIFY_ACCOUNT: 'VERIFY_ACCOUNT',
    SIGN_IN: 'SIGN_IN',
    EXISTING_ORGANIZATION: 'EXISTING_ORGANIZATION',
    HEALTH_SYSTEM_REQUEST: 'HEALTH_SYSTEM_REQUEST',
};

export const getScreenTitle = (screen) => {
    switch (screen) {
        case Screen.CREATE:
            return 'Create your account';
        case Screen.COMPLETE_PROFILE:
            return 'Complete your profile';
        case Screen.EXISTING_ACCOUNT:
            return 'This account already exists';
        case Screen.VERIFY_ACCOUNT:
            return 'Thank you for registering. Please verify your account';
        case Screen.SIGN_IN:
            return 'Thank you for registering! Sign-in';
        case Screen.EXISTING_ORGANIZATION:
            return 'This organization already exists';
        case Screen.HEALTH_SYSTEM_REQUEST:
            return 'Your request has been sent';
    }
};

export const getScreenIcon = (currentScreen) => {
    switch (currentScreen) {
        case Screen.CREATE:
        case Screen.COMPLETE_PROFILE:
            return 'complete-account';
        case Screen.VERIFY_ACCOUNT:
            return 'account-verified';
        case Screen.SIGN_IN:
            return 'check';
        case Screen.EXISTING_ACCOUNT:
            return 'account-exists';
        case Screen.EXISTING_ORGANIZATION:
            return 'org-exists';
        case Screen.HEALTH_SYSTEM_REQUEST:
            return 'mail';
    }
};

export const isValidText = (text, length = 50) => text.length < length && /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/.test(text);

export const isValidAddressText = (text) => text.length < 50 && /^[#.0-9a-zA-Z\s,-]+$/.test(text);

export const isValidEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}[^\\S]))$/.test(
        email
    );
