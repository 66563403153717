import React, {useState, useEffect} from 'react';
import {TextField, Dropdown, Checkbox} from '@partssourceinc/react-ui-core';
import {Button} from '@partssourceinc/react-ui-core/components';
import 'less/Registration/registrationStartPage.less';
import {NavLink} from 'react-router-dom';
import {SignInOption} from './SignInOption';
import states from 'data/States';

export const RegistrationSecondStep = ({
    isBusinessAccount,
    isTaxExempt,
    isExistingOrganization,
    onFieldChange,
    address1,
    address2,
    city,
    zip,
    ext,
    department,
    companyName,
    phoneNumber,
    selectedCountry,
    selectedState,
    selectedOrganizationType,
    selectedPositionType,
    handleOnSubmit,
    handleOnCancel,
    loading,
    companyTypes,
    companyPositionTypes,
    validateBeforeSave,
    onBlur,
    validatedFields,
}) => {
    const countries = [
        {value: 'US', text: 'United States'},
        {value: 'CA', text: 'Canada'},
    ];

    const [showErrors, setShowErrors] = useState(false);
    const [validPhoneNumber, setValidPhoneNumber] = useState(null);
    const [validZipCode, setValidZipCode] = useState(null);

    useEffect(() => {
        dataLayer.push({'event': 'registrationStep','stepName': 'SecondStep'});
    }, []);

    const onPhoneNumberChange = (e) => {
        onFieldChange(e);
        setValidPhoneNumber(isValidPhoneNumber(e.target.value));
    };

    const onZipCodeChange = (e) => {
        onFieldChange(e);
        setValidZipCode(isValidZipCode(e.target.value));
    };

    const isValidZipCode = (pZip) => {
        if (selectedCountry.value === 'US')
            return /^[0-9]{5}(?:-[0-9]{4})?$/i.test(pZip) && pZip.length <= 18;
        return /^[A-Za-z][0-9][A-Za-z][ ][0-9A-Za-z]{3}?$/i.test(pZip) && pZip.length <= 18;
    };
    
    const isValidPhoneNumber = (phoneNumber) => (/^(\([0-9]{3}\)|[0-9]{3}[-.\s]?)[0-9]{3}[-.\s]?[0-9]{4}$/i.test(phoneNumber));

    const onSubmit = (e) => {
        e.preventDefault();
        if (fieldsAreValid()) handleOnSubmit();
    };

    const fieldsAreValid = () => {
        let nonValidFields =
            !validatedFields.address1 ||
            !validatedFields.city ||
            !validPhoneNumber ||
            !validZipCode ||
            !selectedState.value ||
            !selectedCountry.value;

        if (isExistingOrganization || isBusinessAccount) {
            nonValidFields =
              nonValidFields ||
              !selectedPositionType.value;
        }

        if (isBusinessAccount && !isExistingOrganization) {
            nonValidFields = nonValidFields || isTaxExempt === null || !selectedOrganizationType.value || !validatedFields.companyName;
        }

        if (isExistingOrganization) {
            nonValidFields = nonValidFields || !validatedFields.department;
        }

        setShowErrors(nonValidFields);
        return !nonValidFields;
    };

    return (

        <form onSubmit={onSubmit}>
            <div
                className={`registration-start-page-container ${
                    isExistingOrganization && 'existing-org'
                }`}
            >
                <div className="register-inputs">
                    {isBusinessAccount && !isExistingOrganization && (
                        <TextField
                            id="companyName"
                            value={companyName}
                            autoFocus={isBusinessAccount && !isExistingOrganization}
                            type="text"
                            tabIndex="1"
                            showErrorMessage={showErrors && !validatedFields.companyName}
                            onChange={validateBeforeSave}
                            onBlur = {onBlur}
                            text={companyName}
                            placeholder="Company Name"
                            label="Company Name"
                        />
                    )}
                    <div className="phone-number-fields">
                        <TextField
                            id="phoneNumber"
                            type="text"
                            tabIndex="2"
                            autoFocus={!isBusinessAccount || isExistingOrganization}
                            value={phoneNumber}
                            onChange={onPhoneNumberChange}
                            placeholder="Phone Number"
                            showErrorMessage={showErrors && !validPhoneNumber}
                            label="Phone Number"
                        />
                        <TextField
                            type="text"
                            id="ext"
                            tabIndex="3"
                            value={ext}
                            onChange={onFieldChange}
                            placeholder="Ext. (optional)"
                            label="Ext. (optional)"
                        />
                    </div>

                    <TextField
                        id="address1"
                        value={address1}
                        type="text"
                        tabIndex="4"
                        maxLength={50}
                        onBlur = {onBlur}
                        text={address1}
                        showErrorMessage={showErrors && !validatedFields.address1}
                        onChange={validateBeforeSave}
                        placeholder="Address 1"
                        label="Address 1"
                    />
                    <TextField
                        id="address2"
                        value={address2}
                        type="text"
                        tabIndex="5"
                        onBlur = {onBlur}
                        text={address2}
                        maxLength={50}
                        className="secondary-field"
                        showErrorMessage={showErrors && (address2.length > 0 ? !validatedFields.address2 : false)}
                        onChange={validateBeforeSave}
                        placeholder="Address 2 (optional)"
                        label="Address 2 (optional)"
                    />
                    <TextField
                        id="city"
                        value={city}
                        type="text"
                        tabIndex="6"
                        maxLength={50}
                        onBlur = {onBlur}
                        text={city}
                        showErrorMessage={showErrors && !validatedFields.city}
                        onChange={validateBeforeSave}
                        placeholder="City"
                        label="City"
                    />
                    <Dropdown
                        id="selectedState"
                        name="state"
                        label={'State'}
                        className="secondary-field"
                        tabIndex="7"
                        text={selectedState.text}
                        showErrorMessage={showErrors && !selectedState.value}
                        selectedValue={selectedState.value}
                        options={selectedCountry.value === 'US' ? states.US : states.CA}
                        onChange={onFieldChange}
                    />
                    <TextField
                        id="zip"
                        value={zip}
                        type="text"
                        tabIndex="8"
                        maxLength={17}
                        onBlur = {onBlur}
                        text={zip}
                        style={{width: 160}}
                        showErrorMessage={showErrors && !validZipCode}
                        onChange={onZipCodeChange}
                        placeholder="Zip Code"
                        label="Zip Code"
                    />

                    <Dropdown
                        id="selectedCountry"
                        name="country"
                        label={'Country'}
                        className="secondary-field"
                        tabIndex="9"
                        text={selectedCountry.text}
                        selectedValue={selectedCountry.value}
                        showErrorMessage={showErrors && !selectedCountry.value}
                        options={countries}
                        onChange={onFieldChange}
                    />

                    {(isBusinessAccount || isExistingOrganization) && (
                        <React.Fragment>
                            {isExistingOrganization && 
                                <TextField
                                    id="department"
                                    value={department}
                                    onBlur = {onBlur}
                                    text={department}
                                    type="text"
                                    tabIndex="10"
                                    maxLength={50}
                                    showErrorMessage={showErrors && !validatedFields.department}
                                    onChange={validateBeforeSave}
                                    placeholder="Department"
                                    label="Department"
                                />
                            }
                            {!isExistingOrganization && (
                                <Dropdown
                                    id="selectedOrganizationType"
                                    name="selectedOrganizationType"
                                    label={'Organization Type'}
                                    tabIndex="10"
                                    text={selectedOrganizationType.text}
                                    selectedValue={selectedOrganizationType.value}
                                    showErrorMessage={showErrors && !selectedOrganizationType.value}
                                    options={companyTypes}
                                    onChange={onFieldChange}
                                />
                            )}
                            <Dropdown
                                id="selectedPositionType"
                                name="selectedPositionType"
                                label={'Job Role'}
                                tabIndex="11"
                                text={selectedPositionType.text}
                                selectedValue={selectedPositionType.value}
                                showErrorMessage={showErrors && !selectedPositionType.value}
                                options={companyPositionTypes}
                                onChange={onFieldChange}
                            />
                            {!isExistingOrganization && (
                                <React.Fragment>
                                    <h4 className="tax-exempt">Is your organization Tax Exempt?</h4>
                                    <div>
                                        <span
                                            className={`switch ${
                                                isTaxExempt === true && 'switch-selected-type'
                                            } 
                                            ${isTaxExempt === null && showErrors && 'tax-exempt-error'}`}
                                            onClick={() =>
                                                onFieldChange({
                                                    target: {value: true, id: 'isTaxExempt'},
                                                })
                                            }
                                        >
                                            Yes
                                        </span>
                                        <span
                                            style={{marginLeft: 10}}
                                            className={`switch ${
                                                isTaxExempt === false && 'switch-selected-type'
                                            } 
                                            ${isTaxExempt === null && showErrors && 'tax-exempt-error'}`}
                                            onClick={() =>
                                                onFieldChange({
                                                    target: {value: false, id: 'isTaxExempt'},
                                                })
                                            }
                                        >
                                            No
                                        </span>
                                    </div>
                                    {isTaxExempt && <div className="tax-exempt-info">You will be charged tax until your exempt status is verified.  All taxes will be refunded.  To ensure you receive credit for taxes paid, please send your documentation to <a href="mailto:TaxExemptNotification@partssource.com">TaxExemptNotification@partssource.com</a> for approval</div>}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {/* <hr />
                    <Checkbox
                        onChange={() => setAcceptPrivacy(!acceptPrivacy)}
                        checked={acceptPrivacy}
                        showErrorMessage={showErrors || !acceptPrivacy}
                    >
                        <span className="terms-and-privacy">
                            I have read and agree to PartsSource&apos;s{' '}
                            <NavLink target="_blank" to="/policies/termsandconditions">
                            Terms and Conditions
                            </NavLink>
                        , <NavLink target="_blank" to="/policies/termsofuse">Terms of Use</NavLink>,{' '}
                            <NavLink target="_blank" to="/policies/privacypolicy">Privacy Policy</NavLink> and{' '}
                            <NavLink target="_blank" to="/policies/eula">EULA</NavLink>
                        </span>
                    </Checkbox> */}
                    <hr />
                    <div className="complete-profile-footer">
                        {!isExistingOrganization && (
                            <NavLink to="/" className="cancel" onClick={() => handleOnCancel()}>
                            Cancel
                            </NavLink>
                        )}

                        <Button
                            type="submit"
                            className="btn-continue"
                            loading={loading}
                        >
                            {isExistingOrganization ? 'REQUEST ACCESS' : 'CREATE ACCOUNT'}
                        </Button>
                    </div>
                </div>
                {isExistingOrganization && <SignInOption />}
            </div>
        </form>
    );
};
