import React, {useEffect} from "react";
import { Button } from "@partssourceinc/react-ui-core/components";
import "less/Registration/accountAlreadyExists.less";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { NeedHelpAlert } from "./NeedHelpAlert";
import { RegistrationHeader } from "./RegistrationHeader";
import { Screen } from "./helpers";

export const AccountAlreadyExists = () => {
    const history = useHistory();

    useEffect(() => {
        dataLayer.push({'event': 'registrationStep','stepName': 'AccountAlreadyExists'});
    }, []);

    return (
        <React.Fragment>
            <div className="account-already-exists">
                <h4 className="text">
          We already have an account assigned to this email.
                </h4>
                <hr />
                <div className="account-exists-footer">
                    <NavLink className="cancel" to="/login">
            Sign In
                    </NavLink>
                    <Button
                        className="btn-continue"
                        onClick={() => history.push('/forgot')}
                    >
            Reset Password
                    </Button>
                </div>
            </div>
            <NeedHelpAlert />
        </React.Fragment>
    );
};
