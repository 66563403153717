import React from 'react';
import 'less/Registration/signInOption.less';
import {NavLink} from 'react-router-dom';

export const SignInOption = () => {
    return (
        <div className="sign-in-option">
            <hr />
            <h4 className="text">
        Already have an account? <NavLink to="/login">Sign in</NavLink>
            </h4>
        </div>
    );
};
