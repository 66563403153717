import React, {useEffect} from 'react';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import 'less/Registration/registrationStartPage.less';

export const HealthSystemRequest = () => {
    useEffect(() => {
        dataLayer.push({'event': 'registrationStep','stepName': 'HealthSystemRequest'});
    }, []);

    return (
        <div
            style={{
                maxWidth: 450,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <h4 className="white-list-text">
      Your registration is almost complete. You will receive your login credentials shortly. 
      If you would like to place an order right away, please call <a href={'tel:+1' + defaultPhoneNumber}>{defaultPhoneNumber}</a> and we will be happy to assist you!
            </h4>
        </div>
    );
};
