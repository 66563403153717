import React, { useState, useEffect } from "react";
import { TextField } from "@partssourceinc/react-ui-core";
import { Button } from "@partssourceinc/react-ui-core/components";
import "less/Registration/registrationStartPage.less";
import { NeedHelpAlert } from "./NeedHelpAlert";
import { SignInOption } from "./SignInOption";
import {isValidEmail} from "./helpers";

export default function RegistrationFirstStep({
    firstName,
    lastName,
    emailAddress,
    onFieldChange,
    isBusinessAccount,
    handleOnContinue,
    loading,
    validatedFields,
    validateBeforeSave,
    onBlur,
}) {
    const [validEmail, setValidEmail] = useState(null);
    const [showErrors, setShowErrors] = useState(false);

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (!validEmail || !validatedFields.emailAddress || !validatedFields.firstName || !validatedFields.lastName) {
            setShowErrors(true);
        } else {
            setShowErrors(false);
            handleOnContinue();
        }
    };

    const onEmailChange = (e) => {
        setValidEmail(isValidEmail(e.target.value))

        onFieldChange(e);
    };

    useEffect(() => {
        dataLayer.push({'event': 'registrationStep','stepName': 'FirstStep'});
    }, []);

    return (
        <form onSubmit={handleOnSubmit}>
            <div className="registration-start-page-container">
                <div className="account-types row">
                    <div>
                        <h3 className="subtitle">Type of account:</h3>
                    </div>
                    <div>
                        <div className="col-xs-6">
                            <span
                                className={`switch ${
                                    isBusinessAccount && 'switch-selected-type'
                                }`}
                                onClick={() =>
                                    onFieldChange({
                                        target: {value: true, id: 'isBusinessAccount'},
                                    })
                                }
                            >
                Business
                            </span>
                        </div>
                        <div className="col-xs-6">
                            <span
                                className={`switch ${
                                    !isBusinessAccount && 'switch-selected-type'
                                }`}
                                onClick={() =>
                                    onFieldChange({
                                        target: {value: false, id: 'isBusinessAccount'},
                                    })
                                }
                            >
                Personal
                            </span>
                        </div>
                    </div>
                </div>
                <div className="register-inputs">
                    <div className="name-fields">
                        <TextField
                            type="text"
                            autoFocus={true}
                            id="firstName"
                            tabIndex="1"
                            className="_field"
                            maxLength={50}
                            value={firstName}
                            text={firstName}
                            onBlur={onBlur}
                            showErrorMessage={!validatedFields.firstName && showErrors}
                            onChange={validateBeforeSave}
                            placeholder="First Name"
                            label="First Name"
                        />
                        <TextField
                            type="text"
                            tabIndex="2"
                            id="lastName"
                            className="_field"
                            maxLength={50}
                            value={lastName}
                            text={lastName}
                            onChange={validateBeforeSave}
                            onBlur = {onBlur}
                            showErrorMessage={!validatedFields.lastName && showErrors}
                            placeholder="Last Name"
                            label="Last Name"
                        />
                    </div>
                    <TextField
                        type="email"
                        id="emailAddress"
                        tabIndex="2"
                        value={emailAddress}
                        text={emailAddress}
                        onBlur = {onBlur}
                        showErrorMessage={(!validEmail || !validatedFields.emailAddress) && showErrors}
                        onChange={onEmailChange}
                        placeholder={`${isBusinessAccount ? 'Work' : ''} Email Address`}
                        label={`${isBusinessAccount ? 'Work' : ''} Email Address`}
                    />
                    <hr />
                    <div className="row btn-wrapper">
                        <Button
                            type="submit"
                            className="btn-continue"
                            loading={loading}
                            disabled={!validatedFields.firstName || !validatedFields.lastName}
                        >
              CONTINUE
                        </Button>
                    </div>
                </div>
                <SignInOption />

                <NeedHelpAlert />
            </div>
        </form>
    );
}
