import React from 'react';
import './helpers';
import {Screen, getScreenTitle,getScreenIcon} from './helpers';
import 'less/Registration/registrationHeader.less';

export const RegistrationHeader = ({currentScreen}) => {
    const isVerifyAcc = currentScreen === Screen.VERIFY_ACCOUNT;
    const onExistingOrganization = currentScreen === Screen.EXISTING_ORGANIZATION;
    const healthSystemRequest = currentScreen === Screen.HEALTH_SYSTEM_REQUEST;

    return (
        <div className={`registration-header ${(onExistingOrganization || healthSystemRequest) && 'existing-org'}`}>
            <img
                src={`/images/icn_${getScreenIcon(currentScreen)}.png`}
                className={`icon ${(isVerifyAcc || healthSystemRequest) && 'verify-account'}`}
            />
            <h1 className="title">{getScreenTitle(currentScreen)}</h1>
            {onExistingOrganization && <h3 className="subtitle">Please complete the following information to access your organization’s account.</h3>}
        </div>
    );
};
