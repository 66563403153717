import React from 'react';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import 'less/Registration/needHelpAlert.less';
import {NavLink} from 'react-router-dom';

export const NeedHelpAlert = () => {
    return (
        <div className="need-help-alert">
            <div className="triangle" />
            <div className="support-specialist">
                <span className="text">
          Need Help? Email a <a href="mailto: support@partssource.com" style={{textDecoration: 'underline', color: '#000'}}>support specialist</a> or call us at <a href={'tel:+1' + defaultPhoneNumber}>{defaultPhoneNumber}</a>
                </span>
        
            </div>
        </div>
    );
};
